import React from "react";
import { Container, styled } from "@mui/material";
import SiteLayout from "components/layouts/SiteLayout";
import {graphql} from "gatsby";

const Center = styled("div")`
  text-align: center;
`;

const Title = styled("p")`
  color: #0e72d7;
  font-weight: 800;
  line-height: calc(1.5rem + (10 * (100vw - 400px) / 624));
  font-size: calc(1rem + (10 * (100vw - 400px) / 624));
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Heading = styled("p")`
  font-weight: 500;
  line-height: calc(1.5rem + (10 * (100vw - 400px) / 624));
  font-size: calc(1rem + (6 * (100vw - 400px) / 624));
  margin-top: 3.5rem;
  margin-bottom: 1rem;
`;

const Text = styled("p")`
  margin-bottom: 1rem;
  ul {
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 40px;
  }
`;

export default function DeclarationInfoPage() {
  return (
    <SiteLayout>
      <Container>
        <Center>
          <Title>Zapisanie się do przychodni DokDok</Title>
          <Title style={{color: "#ff0000"}}>WAŻNE INFORMACJE</Title>
        </Center>
        <br/>
        <Text>
          Wybierając DokDok na swoją przychodnię podstawowej opieki zdrowotnej
          (składając deklarację) decydujesz się na{" "}
          <b>wypisanie z dotyczasowej przychodni</b>. W ramach Narodowego
          Funduszu Zdrowia możliwe jest korzystanie tylko z jednej przychodni
          POZ. Dwa razy w ciągu roku masz prawo bezpłatnie dokonać zmiany
          przychodni. Zgodnie z zaleceniami Ministerstwa Zdrowia i NFZ, pierwsza
          wizyta w przychodni powinna odbyć się stacjonarnie. Lekarz,
          uwzględniając powody medyczne, ma prawo zdecydować o formie udzielanej
          konsultacji (konsultacja zdalna lub stacjonarna).
        </Text>
        <Heading>Korzystanie z DokDok umożliwia:</Heading>
        <Text>
          <ul>
            <li>
              dostęp do lekarza rodzinnego, 7 dni w tygodniu do 21:00 w
              aplikacji mobilnej
            </li>
            <li>
              dostęp do konsultacji z lekarzem rodzinnym z dowolnego miejsca w
              Polsce
            </li>
            <li>
              otrzymanie e-dokumentów: e-recepta, e-skierowanie, e-zwolnienie{" "}
            </li>
            <li>
              zrealizowanie e-skierowania w dowolnie wybranej poradni
              specjalistycznej na terenie całej Polski
            </li>
            <li>
              wykonanie badań laboratoryjnych w dowolnie wybranym punkcie Synevo lub
              w punkcie pobrań w przychodni DokDok we Wrocławiu i Warszawie
            </li>
            <li>
              dostęp do przychodni stacjonarnej we Wrocławiu i Warszawie,
              a wkrótce w innych miastach w Polsce
            </li>
          </ul>
        </Text>

        <Text>
          Więcej informacji o funkcjonowaniu podstawowej opieki zdrowotnej
          znajdziesz&nbsp;
          <a
            href="https://www.nfz.gov.pl/dla-pacjenta/informacje-o-swiadczeniach/podstawowa-opieka-zdrowotna/"
            target="_blank"
            style={{textDecoration: "underline"}}
          >
            tutaj
          </a>
        </Text>
      </Container>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
